import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import L from 'leaflet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty, find, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { Collapse, Grid, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import {
  Button,
  Card,
  FlexContainer,
  Icon,
  Typography,
  Marker,
  Switch,
} from 'components/atoms';
import { FormControl, Map, Spinner } from 'components/molecules';
import { AddressSuggestion, DataTable } from 'components/organisms';
import {
  getBranchOffice,
  getOrder,
  getTimeWindows,
  patchOrder,
  postOrder,
  getOrderTypes,
  chooseOrganization,
  getClients,
  getCities,
  getDistricts,
} from 'services';
import { optionFormatter } from 'util/functions';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import THEME from 'util/styledTheme';
import { EditProps } from './types';

const Container = styled(FlexContainer)`
  overflow: auto;
`;

const Header = styled(FlexContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`;

const validationState = {
  destiny: ['mandatory', 'validLocation'],
  contact: ['mandatory'],
  email: ['email'],
  phone: ['mandatory'],
};

const validateStatePackage = {
  description: ['mandatory'],
  quantity: ['number'],
  weight: ['number'],
  height: ['number'],
  width: ['number'],
  depth: ['number'],
};

const ComponentSkeleton = () => (
  <>
    <FlexContainer container margin='0 0 20px'>
      <FlexContainer width='45%' margin='0 10px 0 0'>
        <Skeleton variant='rect' width='100%' height='40px' />
      </FlexContainer>
      <FlexContainer width='45%' margin='0 10px 0 0'>
        <Skeleton variant='rect' width='100%' height='40px' />
      </FlexContainer>
    </FlexContainer>
    <FlexContainer container margin='0 0 20px'>
      <Skeleton variant='rect' width='100%' height='300px' />
    </FlexContainer>
    <FlexContainer container>
      <Skeleton variant='rect' width='100%' height='300px' />
    </FlexContainer>
  </>
);

const EditOrder: React.FC<EditProps> = ({
  editId,
  onEdit,
  reprogram,
  onReprogram,
}) => {
  const branchOfficeId = useTypedSelector(
    store => store.organization.selectedBranchOffice.id
  );
  const branchOfficeCode = useTypedSelector(
    store => store.organization.selectedBranchOffice.reference_code
  );
  const organization = useTypedSelector(state => state.organization);
  const user = useTypedSelector(state => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [
    pickupForm,
    onPickupChange,
    validatePickup,
    pickupErrors,
    resetPickup,
    updatePickup,
  ] = useForm(
    {
      taskType: 'Recojo',
      id: 1,
      city: '',
      district: '',
      destiny: '',
      reference: '',
      ubigeo: '',
      contact: '',
      phone: '',
      phone_country: 'pe', // should be dynamic according to the country where it has logged
      email: '',
      latitude: '',
      longitude: '',
    },
    validationState
  );
  const [
    deliveryForms,
    onDeliveryChange,
    validateDelivery,
    deliveryErrors,
    resetDelivery,
    updateDelivery,
    // addForm,
    // deleteForm,
  ] = useForm(
    [
      {
        formId: 1,
        id: 2,
        taskType: 'Entrega',
        city: '',
        district: '',
        destiny: '',
        reference: '',
        ubigeo: '',
        contact: '',
        phone: '',
        phone_country: 'pe', // should be dynamic according to the country where it has logged
        email: '',
        latitude: '',
        longitude: '',
      },
    ],
    validationState
  );

  const [
    packageForm,
    onPackageChange,
    validatePackage,
    packageErrors,
    resetPackage,
  ] = useForm(
    {
      description: '',
      code: '',
      quantity: 1,
      weight: 1.0,
      height: 1.0,
      width: 1.0,
      depth: 1.0,
    },
    validateStatePackage
  );
  // API calls
  const [office, fetchBranchOffice] = useRequest(getBranchOffice);
  const [order, fetchOrder, , , error] = useRequest(getOrder);
  const [timeWindows, fetchTimeWindows, loadingWindows] = useRequest(
    getTimeWindows
  );
  const [orderTypes, fetchOrderTypes, loadingTypes] = useRequest(getOrderTypes);
  const [clients, fetchClients, loadingClients] = useRequest(getClients);
  const [updatedOrder, updateOrder, loadingUpdate, , errorUpdate] = useRequest(
    patchOrder
  );
  const [createdOrder, createOrder, loadingCreate, , errorCreate] = useRequest(
    postOrder
  );
  const [cities, fetchCities, loadingCities] = useRequest(getCities, []);
  const [districts, fetchDistricts, loadingDistricts] = useRequest(
    getDistricts,
    []
  );
  // values
  const [resetAddress, setResetAddress] = useState<number>(1);
  const [timeOptions, setTimeOptions] = useState([]);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [openPickup, setOpenPickup] = useState<boolean>(!!id);
  const [openDelivery, setOpenDelivery] = useState<Array<number>>([1]);
  const [openPackage, setOpenPackage] = useState<boolean>(true);
  const [date, setDate] = useState<string | null>(null);
  const [referenceCode, setReferenceCode] = useState<string>('');
  const [deliveryWindow, setDeliveryWindow] = useState<string>('');
  const [orderType, setOrderType] = useState<string>('');
  const [clientId, setClientId] = useState(null);
  const [immediate, setImmediate] = useState<boolean>(false);
  const [batch, setBatch] = useState<string>('');
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState();
  const [citySelectedPickup, setCitySelectedPickup] = useState();
  const [districtSelectedPickup, setDistrictSelectedPickup] = useState();
  const [citySelectedDropoff, setCitySelectedDropoff] = useState();
  const [districtSelectedDropoff, setDistrictSelectedDropoff] = useState();

  const [loadingOrder, setLoadingOrder] = useState<boolean>(false);

  const [packagesInfo, setPackagesInfo] = useState<any[]>([]);
  const [autoAssign, setAutoAssign] = useState<boolean>(
    JSON.parse(localStorage.getItem('auto_assign_worker')) || false
  );

  const [officeOptions, setOfficeOptions] = useState([]);
  const defaultOption = { id: '', name: 'Todas', reference_code: '' };
  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  useEffect(() => {
    if (id || editId) {
      fetchOrder(id || editId);
      if (reprogram) {
        fetchOrderTypes();
      }
    } else {
      fetchOrderTypes();
      fetchBranchOffice(branchOfficeId);
    }
    fetchTimeWindows({
      organization_branch_office: branchOfficeId,
    });
    fetchClients({ page_size: 0 });
    fetchCities({
      page_size: 0,
    });
  }, [
    id,
    fetchOrder,
    fetchTimeWindows,
    fetchBranchOffice,
    branchOfficeId,
    fetchClients,
    fetchCities,
  ]);

  useEffect(() => {
    if (pickupForm.district) {
      const district = districts.find(d => d.id === pickupForm.district);

      if (district) {
        setDistrictSelectedPickup(district.name);
      }
    }
  }, [pickupForm.district]);

  useEffect(() => {
    if (pickupForm.city) {
      fetchDistricts({
        page_size: 0,
        city__id: pickupForm.city,
      });
      const city = cities.find(c => c.id === pickupForm.city);

      if (city) {
        setCitySelectedPickup(city.name);
      }
    }
  }, [pickupForm.city]);

  useEffect(() => {
    if (deliveryForms[0].district) {
      const district = districts.find(d => d.id === deliveryForms[0].district);

      if (district) {
        setDistrictSelectedDropoff(district.name);
      }
    }
  }, [deliveryForms[0].district]);

  useEffect(() => {
    if (deliveryForms[0].city) {
      fetchDistricts({
        page_size: 0,
        city__id: deliveryForms[0].city,
      });
      const city = cities.find(c => c.id === deliveryForms[0].city);

      if (city) {
        setCitySelectedDropoff(city.name);
      }
    }
  }, [deliveryForms[0].city]);

  useEffect(() => {
    if (!isEmpty(order)) {
      const markerArray = [];
      setDate(order.delivery_programmed_date);
      setDeliveryWindow(order.delivery_time_window);
      setReferenceCode(order.reference_code);
      setClientId(order.client ? order.client.id : null);
      setImmediate(order.is_immediate);
      setBatch(order.batch);
      setAutoAssign(
        JSON.parse(localStorage.getItem('auto_assign_worker')) || false
      );
      setOrderType(order.order_type_code);
      setPackagesInfo(order.purchase_order ? order.purchase_order.items : []);

      order.order_points.forEach((orderPoint, index) => {
        if (orderPoint.coordinate) {
          markerArray.push({
            id: orderPoint.id || index,
            coordinate: orderPoint.coordinate,
          });
        }

        switch (index) {
          case 0:
            updatePickup({
              id: orderPoint.id,
              destiny: orderPoint.address,
              reference: orderPoint.special_instructions,
              ubigeo: orderPoint.task_order_points
                ? `${
                    orderPoint.task_order_points[0]
                      ? orderPoint.task_order_points[0].ubigeo
                      : ''
                  }`
                : '',
              contact: orderPoint.contact_person,
              phone: orderPoint.contact_phone,
              email: orderPoint.contact_email,
              latitude: orderPoint.coordinate && orderPoint.coordinate.latitude,
              longitude:
                orderPoint.coordinate && orderPoint.coordinate.longitude,
            });
            break;
          case 1:
            updateDelivery({
              formId: 1,
              id: orderPoint.id,
              destiny: orderPoint.address,
              reference: orderPoint.special_instructions,
              ubigeo: orderPoint.task_order_points
                ? `${
                    orderPoint.task_order_points[0]
                      ? orderPoint.task_order_points[0].ubigeo
                      : ''
                  }`
                : '',
              contact: orderPoint.contact_person,
              phone: orderPoint.contact_phone,
              email: orderPoint.contact_email,
              latitude: orderPoint.coordinate && orderPoint.coordinate.latitude,
              longitude:
                orderPoint.coordinate && orderPoint.coordinate.longitude,
            });
            break;
          default:
            break;
        }
      });

      setMarkers(markerArray);
    }
  }, [order]);

  useEffect(() => {
    if (!isEmpty(office)) {
      if (office.coordinates) {
        setMarkers([
          {
            id: 0,
            coordinate: office.coordinates,
          },
        ]);
      }
      updatePickup({
        destiny: office.address,
        reference: office.special_instructions,
        contact: office.contact_person,
        phone: office.contact_phone,
        email: office.contact_email,
        latitude: office.coordinates && office.coordinates.latitude,
        longitude: office.coordinates && office.coordinates.longitude,
      });
    }
  }, [office]);

  useEffect(() => {
    if (markers.length > 0) {
      const newBounds = L.latLngBounds(
        markers.map(marker => [
          marker.coordinate.latitude,
          marker.coordinate.longitude,
        ])
      );
      setBounds(newBounds);
    }
  }, [markers]);

  useEffect(() => {
    if (timeWindows.length > 0) {
      setTimeOptions(
        optionFormatter(timeWindows, {
          id: item =>
            editId || id
              ? item.id
              : `${item.start_hour.substring(0, 5)}-${item.end_hour.substring(
                  0,
                  5
                )}`,
          name: item => `${item.start_hour} - ${item.end_hour}`,
        })
      );
    }
  }, [timeWindows]);

  useEffect(() => {
    if (orderTypes.length > 0) {
      setOrderTypeOptions(
        optionFormatter(orderTypes, {
          id: item => item.code,
          name: item => item.name,
        })
      );
      const deliveryOption = find(orderTypes, {
        code: 'delivery',
      });
      if (deliveryOption) {
        setOrderType(deliveryOption.code);
      }
    }
  }, [orderTypes]);

  useEffect(() => {
    if (clients.length > 0) {
      const activeClients = clients.filter(client => client.is_active);
      setClientOptions(
        optionFormatter(activeClients, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(updatedOrder)) {
      enqueueSnackbar('La orden se actualizó correctamente.', {
        variant: 'success',
      });
      if (editId) {
        onEdit();
      }
    }
  }, [updatedOrder, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(createdOrder) && !reprogram) {
      setLoadingOrder(false);
      enqueueSnackbar('La orden se creo correctamente.', {
        variant: 'success',
      });
      resetDelivery();
      // resetPickup();
      resetPackage();
      setDate(null);
      setReferenceCode('');
      setDeliveryWindow('');
      setClientId(null);
      setImmediate(false);
      setAutoAssign(
        JSON.parse(localStorage.getItem('auto_assign_worker')) || false
      );
      setResetAddress(resetAddress + 1);
      const deliveryOption = find(orderTypes, {
        code: 'delivery',
      });
      setOrderType(deliveryOption ? deliveryOption.code : '');
      setMarkers([]);
      setPackagesInfo([]);
    } else if (!isEmpty(createdOrder) && reprogram) {
      setLoadingOrder(false);
      enqueueSnackbar('La orden se reprogramo correctamente.', {
        variant: 'success',
      });
      onReprogram();
    }
  }, [createdOrder, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(errorCreate)) {
      if (!isEmpty(errorCreate.data.errors)) {
        errorCreate.data.errors.forEach(errorCreateOrder => {
          if (
            errorCreateOrder.non_field_errors &&
            errorCreateOrder.non_field_errors[0] ===
              'The Delivery Programmed Date is required'
          ) {
            enqueueSnackbar('La fecha programada es requerida.', {
              variant: 'error',
            });
          } else if (errorCreateOrder === 'The reference code already exist') {
            enqueueSnackbar('El código de referencia ya existe', {
              variant: 'error',
            });
          } else if (
            errorCreateOrder.auto_assign_worker &&
            errorCreateOrder.auto_assign_worker[0] ===
              'Este campo no puede ser nulo.'
          ) {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo.', {
              variant: 'error',
            });
          }
        });
      }
    }
  }, [errorCreate]);

  useEffect(() => {
    if (!isEmpty(errorUpdate)) {
      if (!isEmpty(errorUpdate.data.errors)) {
        errorUpdate.data.errors.forEach(errorUpd => {
          if (
            errorUpd.delivery_programmed_date &&
            errorUpd.delivery_programmed_date[0] ===
              "Can't change because has a route assigned."
          ) {
            enqueueSnackbar(
              'No se puede actualizar porque tiene una ruta asignada.',
              {
                variant: 'error',
              }
            );
          }

          if (
            errorUpd.is_immediate &&
            errorUpd.is_immediate[0] ===
              "Can't change because has a route assigned."
          ) {
            enqueueSnackbar(
              'El inmediato no se puede actualizar porque tiene una ruta asignada.',
              {
                variant: 'error',
              }
            );
          }
        });
      }
    }
  }, [errorUpdate]);

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organization.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (
      (pickupErrors.contact && pickupErrors.contact.includes('obligatorio')) ||
      (pickupErrors.phone && pickupErrors.phone.includes('obligatorio'))
    ) {
      setOpenPickup(true);
    }
  }, [pickupErrors]);

  const validateForms = () => {
    const pickup = validatePickup();
    const delivery = validateDelivery();

    if (pickup && delivery) {
      setLoadingOrder(true);

      if ((editId || id) && !reprogram) {
        const orderPoints = [];
        orderPoints.push({
          id: pickupForm.id,
          coordinates: {
            latitude: pickupForm.latitude,
            longitude: pickupForm.longitude,
          },
          address: pickupForm.destiny,
          contact_person: pickupForm.contact,
          contact_phone: pickupForm.phone,
          contact_email: pickupForm.email,
          special_instructions: pickupForm.reference,
          ubigeo: pickupForm.ubigeo,
        });
        deliveryForms.forEach(deliveryForm => {
          orderPoints.push({
            id: deliveryForm.id,
            coordinates: {
              latitude: deliveryForm.latitude,
              longitude: deliveryForm.longitude,
            },
            address: deliveryForm.destiny,
            contact_person: deliveryForm.contact,
            contact_phone: deliveryForm.phone,
            contact_email: deliveryForm.email,
            special_instructions: deliveryForm.reference,
            ubigeo: deliveryForm.ubigeo,
          });
        });

        updateOrder(
          {
            delivery_time_window_id: deliveryWindow,
            delivery_programmed_date: date,
            batch,
            order_points: orderPoints,
            is_immediate: immediate,
          },
          id || editId
        );
      } else {
        const items = packagesInfo.map(item => ({
          id: item.id,
          description: item.description,
          code: item.code,
          quantity: item.quantity,
          weight: item.weight,
          height: item.height,
          width: item.width,
          depth: item.depth,
          value: item.value,
          updated_at: item.updated_at,
          created_at: item.created_at,
        }));

        const tasks = [];
        tasks.push({
          coordinates: {
            latitude: pickupForm.latitude,
            longitude: pickupForm.longitude,
          },
          address: pickupForm.destiny,
          address_detail: pickupForm.reference,
          contact_person: pickupForm.contact,
          contact_phone: pickupForm.phone,
          contact_email: pickupForm.email,
          task_type: 'pickup',
        });
        deliveryForms.forEach(deliveryForm => {
          tasks.push({
            coordinates: {
              latitude: deliveryForm.latitude,
              longitude: deliveryForm.longitude,
            },
            address: deliveryForm.destiny,
            address_detail: deliveryForm.reference,
            contact_person: deliveryForm.contact,
            contact_phone: deliveryForm.phone,
            contact_email: deliveryForm.email,
            task_type: 'dropoff',
          });
        });

        let timeWindow = null;

        if (reprogram) {
          const window = find(timeWindows, {
            id: deliveryWindow,
          });
          timeWindow = `${window.start_hour.substring(
            0,
            5
          )}-${window.end_hour.substring(0, 5)}`;
        }

        const data = {
          client_id: clientId,
          reference_code: referenceCode,
          order_type: orderType,
          programmed_date: date,
          time_window: timeWindow || deliveryWindow,
          is_immediate: immediate,
          auto_assign_worker: autoAssign,
          branch_office: branchOfficeCode,
          rescheduled_order_id: order.rescheduled_order || editId,
          tasks,
          purchase_order: {
            items,
          },
        };

        localStorage.setItem('auto_assign_worker', JSON.stringify(autoAssign));

        createOrder(reprogram ? data : omit(data, ['rescheduled_order_id']));
      }
    }
  };

  const handleAddressUpdate = async (
    location,
    formId?: number,
    orderId?: string,
    drag?: boolean
  ) => {
    let updateValues;

    let newMarkers = [];

    if (location.place_id) {
      // if is google maps autocomplete service
      const geocoder = new window.google.maps.Geocoder();
      updateValues = await geocoder
        .geocode({ placeId: location.place_id })
        .then(({ results }) => {
          return (updateValues = {
            destiny: location.title,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        });
    } else {
      updateValues = {
        destiny: location.description || location.formatted_address,
        latitude: location.geometry.location.lat,
        longitude: location.geometry.location.lng,
      };
    }
    if (formId) {
      onDeliveryChange(
        location.description || location.formatted_address,
        'destiny',
        formId
      );

      updateDelivery({
        ...updateValues,
        formId,
      });

      if (drag) {
        newMarkers = markers.filter(marker => marker.id !== orderId);
      }

      newMarkers = (drag ? newMarkers : markers).concat({
        id: orderId,
        coordinate: {
          latitude: updateValues.latitude,
          longitude: updateValues.longitude,
        },
      });
    } else {
      const officeCoordinate = markers.find(marker => marker.id === 0);
      if (officeCoordinate) {
        markers.splice(0, 1);
      }

      updatePickup(updateValues);

      if (drag) {
        newMarkers = markers.filter(marker => marker.id !== pickupForm.id);
      }

      newMarkers = (drag ? newMarkers : markers).concat({
        id: pickupForm.id,
        coordinate: {
          latitude: updateValues.latitude,
          longitude: updateValues.longitude,
        },
      });
    }

    const lastObjectsById = {};
    newMarkers.forEach(newMarker => {
      lastObjectsById[newMarker.id] = newMarker;
    });
    const filteredMarkers = Object.values(lastObjectsById);
    setMarkers(newMarkers);
  };

  const handleResetAddress = (formId?: number, orderId?: string) => {
    const resetValues = {
      destiny: '',
      latitude: null,
      longitude: null,
    };
    let newMarkers = [];

    if (formId) {
      updateDelivery({
        ...resetValues,
        formId,
      });

      newMarkers = markers.filter(marker => marker.id !== orderId);
    } else {
      updatePickup(resetValues);

      newMarkers = markers.filter(marker => marker.id !== pickupForm.id);
    }

    setMarkers(newMarkers);
  };

  const handleMarkerDrag = (marker, markerId, index) => {
    const coors = marker.getLatLng();
    // const params = {
    //   key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    //   latlng: `${coors.lat},${coors.lng}`,
    // };
    const params = {
      key: 'AIzaSyAtRZxo0j5YbCyZSdLkeGnaRVq5IUSZ0Q8',
      latlng: `${coors.lat},${coors.lng}`,
    };
    axios({
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json`,
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(results => {
        if (results.data.results.length > 0) {
          if (index) {
            handleAddressUpdate(results.data.results[0], 1, markerId, true);
          } else {
            handleAddressUpdate(
              results.data.results[0],
              undefined,
              undefined,
              true
            );
          }
        }
      })
      .catch(() => {
        enqueueSnackbar('Ocurrio un error con la dirección.', {
          variant: 'error',
        });
      });
  };

  const handleResetForms = () => {
    resetDelivery();
    resetPickup();
    setResetAddress(resetAddress + 1);
    resetPackage();
    setPackagesInfo([]);
  };

  const handleAddPackage = () => {
    const items = validatePackage();

    if (items) {
      setPackagesInfo([...packagesInfo, packageForm]);
      resetPackage();
    }
  };

  const handleDelete = (description: string) => {
    const newPackages = packagesInfo.filter(
      packageInfo => packageInfo.description !== description
    );
    setPackagesInfo(newPackages);
  };

  const headers = useMemo(
    () => [
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description,
      },
      {
        label: 'SKU',
        id: 'sku',
        cell: row => row.code,
      },
      {
        label: 'Cantidad',
        id: 'quantity',
        cell: row => `${row.quantity} unidad`,
      },
      {
        label: 'Peso',
        id: 'weight',
        cell: row => `${row.weight} kg.`,
      },
      {
        label: 'Volumen',
        id: 'volumn',
        cell: row => `${row.height}x${row.width}x${row.depth}cm`,
      },
      {
        label: '',
        id: 'sku',
        cell: row => (
          <IconButton
            size='small'
            onClick={() => handleDelete(row.description)}
          >
            <Icon icon='cancel-icon' size={12} />
          </IconButton>
        ),
      },
    ],
    [packagesInfo]
  );

  return (
    <FlexContainer container height='100%'>
      <FlexContainer
        width='50%'
        height='100%'
        margin='0 5px 0 0'
        style={{ overflow: 'auto' }}
      >
        <Container container direction='column' padding='25px'>
          {(editId || id) && isEmpty(order) && !error && <ComponentSkeleton />}
          {(!isEmpty(order) || !(editId || id)) && (
            <>
              <FlexContainer
                container
                alignItems='center'
                padding='20px'
                margin='0 0 20px'
                backgroundColor='#FFFFFF'
                borderColor={THEME.colors.borderColor}
                wrap='wrap'
              >
                {(!(editId || id) || reprogram) && (
                  <>
                    <FormControl
                      control='select'
                      placeholder='Seleccione una organización'
                      value={JSON.stringify(organization.selectedBranchOffice)}
                      margin='0 20px 15px 0'
                      options={officeOptions}
                      defaultOption={toSelectDefaultOption}
                      onChange={value => {
                        const parsedValue = JSON.parse(value);
                        dispatch(
                          selectBranchOffice({
                            id: parsedValue.id,
                            name: parsedValue.name,
                            reference_code: parsedValue.reference_code,
                          })
                        );
                      }}
                      disabled={
                        loadingUpdate ||
                        loadingWindows ||
                        ((editId || id) && !reprogram)
                      }
                    />
                    <FormControl
                      control='select'
                      placeholder='Seleccione cliente'
                      value={clientId}
                      margin='0 20px 15px 0'
                      options={clientOptions}
                      onChange={value => setClientId(value)}
                      disabled={loadingCreate || loadingClients}
                    />
                    <FormControl
                      control='select'
                      options={orderTypeOptions}
                      onChange={value => setOrderType(value)}
                      value={orderType}
                      margin='0 20px 0 0'
                      disabled={loadingCreate || loadingTypes}
                    />
                    <FormControl
                      placeholder='Ingrese código de referencia'
                      onChange={value => setReferenceCode(value)}
                      value={referenceCode}
                      margin='0 20px 0 0'
                      disabled={loadingUpdate || ((editId || id) && !reprogram)}
                    />
                  </>
                )}
                <FlexContainer alignItems='center' margin='10px 0 0'>
                  <Typography fontSize={12}>Inmediato:</Typography>
                  <Switch
                    checked={immediate}
                    onChange={event => setImmediate(event.target.checked)}
                  />
                </FlexContainer>
              </FlexContainer>

              <FlexContainer
                container
                direction='column'
                alignItems='center'
                padding='20px'
                margin='0 0 20px'
                backgroundColor='#FFFFFF'
                borderColor={THEME.colors.borderColor}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      control='date'
                      label='Programar Fecha'
                      placeholder='Ingrese fecha'
                      onChange={value => setDate(value)}
                      value={date}
                      margin='0 20px 0 0'
                      disabled={loadingUpdate || loadingWindows}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      control='select'
                      options={timeOptions}
                      label='Ventana Horaria'
                      placeholder='Seleccione ventana'
                      onChange={value => setDeliveryWindow(value)}
                      value={deliveryWindow}
                      margin='0'
                      disabled={loadingUpdate || loadingWindows}
                    />
                  </Grid>
                </Grid>
                {(editId || id || reprogram) && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Order batch'
                        placeholder='Ingrese batch'
                        onChange={value => setBatch(value)}
                        value={batch}
                        margin='20px 0 0'
                        width='100%'
                        disabled={loadingUpdate || loadingWindows}
                      />
                    </Grid>
                  </Grid>
                )}
              </FlexContainer>

              <Card shadow width='100%' margin='0 0 30px'>
                <Header
                  container
                  cursor='pointer'
                  padding='10px 20px'
                  justify='space-between'
                  alignItems='center'
                  onClick={() => setOpenPickup(!openPickup)}
                >
                  <FlexContainer alignItems='center' width='calc(100% - 50px)'>
                    <Typography fontSize={16}>Recojo</Typography>
                    {pickupForm.destiny && (
                      <Typography
                        title={pickupForm.destiny}
                        color='text.secondary'
                        fontSize={14}
                        margin='0 0 0 15px'
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {pickupForm.destiny}
                      </Typography>
                    )}
                  </FlexContainer>

                  <div
                    style={{
                      transform: openPickup ? 'rotate(180deg)' : 'none',
                      transition: 'all 0.3s',
                    }}
                  >
                    <IconButton>
                      <Icon icon='arrow-icon' size={15} />
                    </IconButton>
                  </div>
                </Header>

                <Collapse in={openPickup} timeout='auto'>
                  <FlexContainer
                    container
                    direction='column'
                    margin='30px 0 0'
                    padding='0 20px'
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          control='select'
                          label='Ciudad'
                          placeholder='Selecciona ciudad'
                          onChange={value => onPickupChange(value, 'city')}
                          value={pickupForm.city}
                          options={cities}
                          margin='0 0 20px'
                          error={pickupErrors.city}
                          width='100%'
                          disabled={
                            loadingCreate || loadingUpdate || loadingCities
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          control='select'
                          label='Distrito'
                          placeholder='Selecciona un distrito'
                          onChange={value => onPickupChange(value, 'district')}
                          value={pickupForm.district}
                          options={districts}
                          margin='0 0 20px'
                          width='100%'
                          disabled={
                            loadingCreate ||
                            loadingUpdate ||
                            loadingDistricts ||
                            !pickupForm.city
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <AddressSuggestion
                          label='Dirección'
                          placeholder='Ingrese la dirección'
                          value={pickupForm.destiny}
                          onChange={() => handleResetAddress()}
                          onSelect={location => handleAddressUpdate(location)}
                          margin='0 0 20px'
                          disabled={loadingUpdate}
                          error={pickupErrors.destiny}
                          city={citySelectedPickup}
                          district={districtSelectedPickup}
                          // reset={resetAddress}
                        />
                      </Grid>
                    </Grid>
                    {(editId || id || reprogram) && (
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormControl
                            label='Ubigeo'
                            placeholder='Ingrese ubigeo'
                            onChange={value =>
                              onPickupChange(value, 'ubigeo', pickupForm.formId)
                            }
                            value={pickupForm.ubigeo}
                            margin='0 0 20px'
                            width='100%'
                            error={
                              deliveryErrors[pickupForm.formId] &&
                              deliveryErrors[pickupForm.formId].ubigeo
                            }
                            disabled={loadingUpdate}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          label='Contacto'
                          placeholder='Nombres y Apellido de contacto'
                          onChange={value => onPickupChange(value, 'contact')}
                          value={pickupForm.contact}
                          margin='0 0 20px'
                          width='100%'
                          error={pickupErrors.contact}
                          disabled={loadingUpdate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          label='Referencia'
                          placeholder='primer piso, 3° casa a la izquierda'
                          onChange={value => onPickupChange(value, 'reference')}
                          value={pickupForm.reference}
                          margin='0 0 20px'
                          width='100%'
                          error={pickupErrors.reference}
                          disabled={loadingUpdate}
                          optional
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          control='phone'
                          country={pickupForm.phone_country}
                          label='Teléfono'
                          placeholder='Ingrese número teléfono'
                          onChange={value => onPickupChange(value, 'phone')}
                          value={pickupForm.phone}
                          margin='0 0 20px'
                          width='100%'
                          error={pickupErrors.phone}
                          disabled={loadingUpdate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          label='Correo'
                          placeholder='Escribir correo'
                          onChange={value => onPickupChange(value, 'email')}
                          value={pickupForm.email}
                          margin='0 0 20px'
                          width='100%'
                          error={pickupErrors.email}
                          disabled={loadingUpdate}
                          optional
                        />
                      </Grid>
                    </Grid>
                  </FlexContainer>
                </Collapse>
              </Card>

              {deliveryForms.map(deliveryForm => (
                <Card
                  shadow
                  width='100%'
                  margin='0 0 20px'
                  key={deliveryForm.formId}
                >
                  <Header
                    container
                    cursor='pointer'
                    padding='10px 20px'
                    justify='space-between'
                    alignItems='center'
                    onClick={() => {
                      if (openDelivery.includes(deliveryForm.formId)) {
                        setOpenDelivery(
                          openDelivery.filter(
                            delivery => delivery !== deliveryForm.formId
                          )
                        );
                      } else {
                        setOpenDelivery(
                          openDelivery.concat(deliveryForm.formId)
                        );
                      }
                    }}
                  >
                    <Typography fontSize={16}>Entrega</Typography>
                    <div
                      style={{
                        transform: openDelivery.includes(deliveryForm.formId)
                          ? 'rotate(180deg)'
                          : 'none',
                        transition: 'all 0.3s',
                      }}
                    >
                      <IconButton>
                        <Icon icon='arrow-icon' size={15} />
                      </IconButton>
                    </div>
                  </Header>

                  <Collapse
                    in={openDelivery.includes(deliveryForm.formId)}
                    timeout='auto'
                  >
                    <FlexContainer
                      container
                      direction='column'
                      margin='30px 0 0'
                      padding='0 20px'
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            control='select'
                            label='Ciudad'
                            placeholder='Selecciona ciudad'
                            onChange={value =>
                              onDeliveryChange(
                                value,
                                'city',
                                deliveryForm.formId
                              )
                            }
                            value={deliveryForm.city}
                            options={cities}
                            margin='0 0 20px'
                            error={
                              deliveryErrors[deliveryForm.formId] &&
                              deliveryErrors[deliveryForm.formId].city
                            }
                            width='100%'
                            disabled={
                              loadingCreate || loadingUpdate || loadingCities
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            control='select'
                            label='Distrito'
                            placeholder='Selecciona un distrito'
                            onChange={value =>
                              onDeliveryChange(
                                value,
                                'district',
                                deliveryForm.formId
                              )
                            }
                            value={deliveryForm.district}
                            options={districts}
                            margin='0 0 20px'
                            width='100%'
                            disabled={
                              loadingCreate ||
                              loadingUpdate ||
                              loadingDistricts ||
                              !deliveryForm.city
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <AddressSuggestion
                            label='Dirección'
                            placeholder='Ingrese la dirección'
                            onChange={() =>
                              handleResetAddress(
                                deliveryForm.formId,
                                deliveryForm.id
                              )
                            }
                            value={deliveryForm.destiny}
                            onSelect={location =>
                              handleAddressUpdate(
                                location,
                                deliveryForm.formId,
                                deliveryForm.id
                              )
                            }
                            margin='0 0 20px'
                            disabled={loadingUpdate}
                            error={
                              deliveryErrors[deliveryForm.formId] &&
                              deliveryErrors[deliveryForm.formId].destiny
                            }
                            reset={resetAddress}
                            city={citySelectedDropoff}
                            district={districtSelectedDropoff}
                          />
                        </Grid>
                      </Grid>
                      {(editId || id || reprogram) && (
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormControl
                              label='Ubigeo'
                              placeholder='Ingrese ubigeo'
                              onChange={value =>
                                onDeliveryChange(
                                  value,
                                  'ubigeo',
                                  deliveryForm.formId
                                )
                              }
                              value={deliveryForm.ubigeo}
                              margin='0 0 20px'
                              width='100%'
                              error={
                                deliveryErrors[deliveryForm.formId] &&
                                deliveryErrors[deliveryForm.formId].ubigeo
                              }
                              disabled={loadingUpdate}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            label='Contacto'
                            placeholder='Nombres y Apellido de contacto'
                            onChange={value =>
                              onDeliveryChange(
                                value,
                                'contact',
                                deliveryForm.formId
                              )
                            }
                            value={deliveryForm.contact}
                            margin='0 0 20px'
                            width='100%'
                            error={
                              deliveryErrors[deliveryForm.formId] &&
                              deliveryErrors[deliveryForm.formId].contact
                            }
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            label='Referencia'
                            placeholder='primer piso, 3° casa a la izquierda'
                            onChange={value =>
                              onDeliveryChange(
                                value,
                                'reference',
                                deliveryForm.formId
                              )
                            }
                            value={deliveryForm.reference}
                            margin='0 0 20px'
                            width='100%'
                            error={
                              deliveryErrors[deliveryForm.formId] &&
                              deliveryErrors[deliveryForm.formId].reference
                            }
                            disabled={loadingUpdate}
                            optional
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            control='phone'
                            country={deliveryForm.phone_country}
                            label='Teléfono'
                            placeholder='Ingrese número teléfono'
                            onChange={value =>
                              onDeliveryChange(
                                value,
                                'phone',
                                deliveryForm.formId
                              )
                            }
                            value={deliveryForm.phone}
                            margin='0 0 20px'
                            width='100%'
                            error={
                              deliveryErrors[deliveryForm.formId] &&
                              deliveryErrors[deliveryForm.formId].phone
                            }
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            label='Correo'
                            placeholder='Escribir correo'
                            onChange={value =>
                              onDeliveryChange(
                                value,
                                'email',
                                deliveryForm.formId
                              )
                            }
                            value={deliveryForm.email}
                            margin='0 0 20px'
                            width='100%'
                            error={
                              deliveryErrors[deliveryForm.formId] &&
                              deliveryErrors[deliveryForm.formId].email
                            }
                            disabled={loadingUpdate}
                            optional
                          />
                        </Grid>
                      </Grid>
                    </FlexContainer>
                  </Collapse>
                </Card>
              ))}
              {(!(editId || id) || reprogram) && (
                <Card shadow width='100%' margin='0 0 30px'>
                  <Header
                    container
                    cursor='pointer'
                    padding='10px 20px'
                    justify='space-between'
                    alignItems='center'
                    onClick={() => setOpenPackage(!openPackage)}
                  >
                    <Typography fontSize={16}>Agregar paquetes</Typography>
                    <div
                      style={{
                        transform: openPackage ? 'rotate(180deg)' : 'none',
                        transition: 'all 0.3s',
                      }}
                    >
                      <IconButton>
                        <Icon icon='arrow-icon' size={15} />
                      </IconButton>
                    </div>
                  </Header>
                  <Collapse in={openPackage} timeout='auto'>
                    <FlexContainer
                      container
                      direction='column'
                      margin='30px 0 0'
                      padding='0 20px'
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={8}>
                          <FormControl
                            label='Descripción'
                            onChange={value =>
                              onPackageChange(value, 'description')
                            }
                            value={packageForm.description}
                            margin='0 0 20px'
                            width='100%'
                            error={packageErrors.description}
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl
                            label='SKU'
                            onChange={value => onPackageChange(value, 'code')}
                            value={packageForm.code}
                            margin='0 0 20px'
                            width='100%'
                            disabled={loadingUpdate}
                            optional
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <FormControl
                            label='Cantidad'
                            placeholder='1'
                            onChange={value =>
                              onPackageChange(value, 'quantity')
                            }
                            value={packageForm.quantity}
                            margin='0 0 20px'
                            width='100%'
                            error={packageErrors.quantity}
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <FormControl
                            label='Peso (kg)'
                            placeholder='kg'
                            onChange={value => onPackageChange(value, 'weight')}
                            value={packageForm.weight}
                            margin='0 0 20px'
                            width='100%'
                            error={packageErrors.weight}
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <FormControl
                            label='Alto (cm)'
                            placeholder='cm'
                            onChange={value => onPackageChange(value, 'height')}
                            value={packageForm.height}
                            margin='0 0 20px'
                            width='100%'
                            error={packageErrors.height}
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <FormControl
                            label='Ancho (cm)'
                            placeholder='cm'
                            onChange={value => onPackageChange(value, 'width')}
                            value={packageForm.width}
                            margin='0 0 20px'
                            width='100%'
                            error={packageErrors.width}
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <FormControl
                            label='Largo (cm)'
                            placeholder='cm'
                            onChange={value => onPackageChange(value, 'depth')}
                            value={packageForm.depth}
                            margin='0 0 20px'
                            width='100%'
                            error={packageErrors.depth}
                            disabled={loadingUpdate}
                          />
                        </Grid>
                      </Grid>
                      <FlexContainer
                        container
                        margin=' 0 0 20px'
                        justify='flex-end'
                      >
                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={() => handleAddPackage()}
                        >
                          Agregar paquete
                        </Button>
                      </FlexContainer>
                      <FlexContainer
                        container
                        padding='15px 0 8px'
                        margin={packagesInfo.length === 0 ? '0 0 15px' : '0'}
                        justify='space-between'
                      >
                        <Typography fontSize={14}>
                          {packagesInfo.length === 0
                            ? 'No hay paquetes agregados'
                            : `Paquetes agregados (${packagesInfo.length})`}
                        </Typography>
                        <Typography fontSize={14}>
                          {packagesInfo.length !== 0 &&
                            `Peso total: ${packagesInfo
                              .reduce(
                                (accum, packageInfo) =>
                                  accum +
                                  +packageInfo.weight * +packageInfo.quantity,
                                0
                              )
                              .toFixed(2)}kg`}
                        </Typography>
                      </FlexContainer>
                      {packagesInfo.length > 0 && (
                        <FlexContainer container margin='0 0 10px'>
                          <DataTable
                            headers={headers.filter(header => header)}
                            data={packagesInfo}
                            onChangePage={() => {}}
                            totalPages={0}
                            totalItems={0}
                            pageSize={0}
                            page={1}
                            showPagination={false}
                          />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Collapse>
                </Card>
              )}
              <Grid container>
                <Grid item xs={12} sm={6}>
                  {(!(editId || id) || reprogram) &&
                    user.permissions.includes('core.worker.list') && (
                      <FlexContainer alignItems='center' margin='0 0 0 10px'>
                        <Typography fontSize={13}>
                          Asignar conductor automáticamente:
                        </Typography>
                        <Switch
                          checked={autoAssign}
                          onChange={event =>
                            setAutoAssign(event.target.checked)
                          }
                        />
                      </FlexContainer>
                    )}
                </Grid>
                <Grid item xs={12} sm>
                  <FlexContainer justify='space-between'>
                    {id ? (
                      <div />
                    ) : (
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => handleResetForms()}
                      >
                        Limpiar campos
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => validateForms()}
                      disabled={loadingUpdate || loadingCreate || loadingOrder}
                    >
                      {(loadingCreate || loadingUpdate || loadingOrder) && (
                        <Spinner
                          height='15px'
                          spinnerSize={20}
                          colorSecondary
                          margin='0 8px 0 0'
                        />
                      )}
                      {reprogram
                        ? 'Reprogramar'
                        : `${editId || id ? 'Guardar cambios' : 'Crear orden'}`}
                    </Button>
                  </FlexContainer>
                </Grid>
              </Grid>
            </>
          )}
          {(editId || id) && error && <div>No se encuentra la orden</div>}
        </Container>
      </FlexContainer>
      <FlexContainer width='50%' height='100%' margin='0 0 0 5px'>
        <Map bounds={bounds} zoom={10} position={[-12.1166362, -77.0138613]}>
          {markers.map((marker, index) => (
            <Marker
              key={marker.id}
              position={[
                marker.coordinate.latitude,
                marker.coordinate.longitude,
              ]}
              icon='colorFlag'
              draggable
              onDragEnd={e => handleMarkerDrag(e.target, marker.id, index)}
            />
          ))}
        </Map>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditOrder;
