import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import L from 'leaflet';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useForm from 'hooks/useForm';
import { getCities, createOrganization, chooseOrganization } from 'services';
import { TypeUsers } from 'store/organization/types';
import { selectOrganization } from 'store/organization/actions';
import { FormControl, Map } from 'components/molecules';
import { Typography, Button, Marker, FlexContainer } from 'components/atoms';
import { AddressSuggestion } from 'components/organisms';
import { productsStorage, countryOptions } from './data';
import { OrganizationType } from './types';

const SecondStepContainer = styled(Grid)`
  height: auto;
  background-color: #ffffff;

  @media only screen and (min-width: 960px) {
    height: 100vh;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1.2fr 1fr;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 768px) and (max-width: 955px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 956px) and (max-width: 1190px) {
    grid-template-columns: 1.3fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 1191px) {
    grid-template-columns: 1.1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 80%;
  padding: 50px 0;

  @media only screen and (min-width: 560px) and (max-width: 767px) {
    width: 500px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 330px;
  }

  @media only screen and (min-width: 960px) {
    width: 460px;
  }
`;

const MapSection = styled(FlexContainer)`
  border-radius: 0;

  @media only screen and (min-width: 768px) {
    border-radius: 48px 0 0 48px;
  }
`;

const MapContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  border-radius: 0;

  @media only screen and (min-width: 768px) {
    height: 100%;

    & > div > div {
      border-radius: 48px 0 0 48px;
    }
  }
`;

const SecondStep = ({ changeStep, setProgress, setDisplayProgressBar }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const validationStateStep = {
    tradename: ['mandatory'],
    product_storage_type: ['mandatory'],
    address: ['mandatory'],
    country_id: ['mandatory'],
    city_id: ['mandatory'],
  };

  const [form, onFormChange, validate, errors, , updateForm] = useForm(
    {
      tradename: '',
      product_storage_type: '',
      address: '',
      coordinates: null,
      country_id: '',
      city_id: '',
    },
    validationStateStep
  );

  const [resetAddress, setResetAddress] = useState<number>(1);
  const [cityOptions, setCityOptions] = useState([]);
  const [bounds, setBounds] = useState();
  const [userEmail, setUserEmail] = useState('');
  const windowHost = window.location.host;

  useEffect(() => {
    if (form.coordinates) {
      const newBounds = L.latLngBounds([
        { lat: form.coordinates.latitude, lng: form.coordinates.longitude },
      ]);
      setBounds(newBounds);
    }
  }, [form.coordinates]);

  useEffect(() => {
    setDisplayProgressBar(true);
    setUserEmail(localStorage.getItem('user_email'));
  }, []);

  const handleAddressUpdate = event => {
    const position = {
      latitude: event.geometry.location.lat,
      longitude: event.geometry.location.lng,
    };
    updateForm({
      address: event.description || event.formatted_address,
      coordinates: position,
    });
  };

  const handleMarkerDrag = e => {
    const coords = e.target.getLatLng();
    // const params = {
    //   key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    //   latlng: `${coords.lat},${coords.lng}`,
    // };
    const params = {
      key: 'AIzaSyAtRZxo0j5YbCyZSdLkeGnaRVq5IUSZ0Q8',
      latlng: `${coords.lat},${coords.lng}`,
    };
    axios({
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json`,
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(results => {
        if (results.data.results.length > 0) {
          handleAddressUpdate(results.data.results[0]);
        } else {
          handleAddressUpdate(results.data.results[0]);
        }
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error con la dirección', {
          variant: 'error',
        });
      });
  };

  const organizationFormatter = (organizations: Array<OrganizationType>) => {
    return organizations.map(item => {
      const typeOfUsers: Array<TypeUsers> = [];
      if (item.administrator_branch_offices.length > 0) {
        typeOfUsers.push({
          id: 'administrator_branch_offices',
          name: 'Administrador',
          branchOffices: item.administrator_branch_offices,
        });
      }
      return {
        id: item.id.toString(),
        name: item.tradename,
        photo: item.photo,
        typeOfUsers,
        slug: item.slug,
        plan: item.pricing_plan.code,
        planName: item.pricing_plan.name,
      };
    });
  };

  const defineOrganization = () => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organization = organizationFormatter(res.data)[0];
      const uniqueOrg = {
        id: parseInt(organization.id, 10),
        name: organization.name,
        photo: organization.photo,
        typeUser: organization.typeOfUsers[0],
        slug: organization.slug,
        plan: organization.plan,
        planName: organization.planName,
      };
      localStorage.setItem(
        'AIMO_ADMIN_ORGANIZATION_ID',
        uniqueOrg.id.toString()
      );
      const name = form.tradename.replace(/ /g, '');
      localStorage.setItem('country', form.country_id);
      localStorage.setItem('name', name.toLowerCase());
      dispatch(selectOrganization(uniqueOrg));
    });
  };

  const validateForm = () => {
    if (validate()) {
      createOrganization(form).then(response => {
        if (response.status === 201) {
          if (windowHost === 'app.aimo.co') {
            // @ts-ignores
            window.analytics.identify(userEmail, {
              created_at: moment().format(),
            });
            // @ts-ignores
            window.analytics.track('Organization created', {
              email: userEmail,
              product_storage_type: form.product_storage_type,
              company_name: form.tradename,
              country_id: form.country_id,
              city_id: form.city_id,
              address: form.address,
              created_at: moment().format(),
            });
          }
          localStorage.removeItem('user_email');
          defineOrganization();
          setProgress(50);
          changeStep(3);
        } else {
          console.log(response);
        }
      });
    }
  };

  const updateCities = (value: string) => {
    getCities({ state__country__code: value, page_size: 0 }).then(response =>
      setCityOptions(response.data)
    );
  };

  return (
    <SecondStepContainer container>
      <Container>
        <FlexContainer
          container
          width='100%'
          height='100%'
          justify='center'
          alignItems='center'
        >
          <FormContainer direction='column'>
            <Typography
              fontSize='27px'
              margin='0'
              fontWeight={600}
              color='#181818'
            >
              Empecemos!
            </Typography>
            <Typography fontSize='25px' color='#181818'>
              Crea tu primera sucursal
            </Typography>

            <FormControl
              label='¿De dónde envías tus productos?'
              placeholder='Selecciona de dónde envías tus productos'
              onChange={value => onFormChange(value, 'product_storage_type')}
              value={form.product_storage_type}
              margin='50px 0px 20px'
              width='100%'
              control='select'
              error={errors.product_storage_type}
              options={productsStorage}
              variant='standard'
            />

            <FormControl
              label='Nombre del negocio'
              placeholder='Escribe el nombre del negocio'
              onChange={value => onFormChange(value, 'tradename')}
              value={form.tradename}
              margin='0 0 20px'
              width='100%'
              control='input'
              variant='standard'
              error={errors.tradename}
            />

            <AddressSuggestion
              label='Dirección'
              placeholder='Escribe la dirección'
              variant='standard'
              error={errors.address}
              onChange={() => {
                updateForm({
                  address: '',
                  coordinates: null,
                });
              }}
              onSelect={async value => {
                let updateValues;

                if (value.place_id) {
                  // if is google maps autocomplete service
                  const geocoder = new window.google.maps.Geocoder();
                  updateValues = await geocoder
                    .geocode({ placeId: value.place_id })
                    .then(({ results }) => {
                      return (updateValues = {
                        destiny: value.title,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng(),
                      });
                    });
                } else {
                  updateValues = {
                    destiny: updateValues.destiny,
                    latitude: value.geometry.location.lat,
                    longitude: value.geometry.location.lng,
                  };
                }

                updateForm({
                  address: updateValues.destiny,
                  coordinates: {
                    latitude: updateValues.latitude,
                    longitude: updateValues.longitude,
                  },
                });
              }}
              value={form.address}
              margin='0 0 20px'
              reset={resetAddress}
            />

            <FormControl
              control='country'
              options={countryOptions}
              label='País'
              placeholder='Selecciona tu país'
              onChange={value => {
                onFormChange(value, 'country_id');
                updateCities(value);
              }}
              value={form.country_id}
              margin='0 0px 20px'
              width='100%'
              error={errors.country_id}
            />
            <FormControl
              control='selectSearch'
              options={cityOptions}
              label='Ciudad'
              placeholder='Selecciona tu ciudad'
              onChange={value => onFormChange(value, 'city_id')}
              value={form.city_id}
              margin='0 0px 50px'
              width='100%'
              variant='standard'
              error={errors.city_id}
            />

            <Grid container alignItems='center' direction='column'>
              <Button
                fullWidth
                height='45px'
                borderRadius='16px;'
                variant='contained'
                color='primary'
                onClick={() => validateForm()}
              >
                <Typography
                  fontWeight='700'
                  fontSize='15px'
                  color='white'
                  letterSpacing='0.5px'
                >
                  Continuar
                </Typography>
              </Button>
            </Grid>
          </FormContainer>
        </FlexContainer>
        <MapSection
          container
          height='100%'
          justify='center'
          alignItems='center'
        >
          <MapContainer>
            <Map
              zoom={13}
              position={[-12.1166362, -77.0138613]}
              bounds={bounds}
            >
              {form.coordinates && (
                <Marker
                  position={[
                    form.coordinates.latitude,
                    form.coordinates.longitude,
                  ]}
                  icon='build'
                  draggable
                  onDragEnd={e => handleMarkerDrag(e)}
                />
              )}
            </Map>
          </MapContainer>
        </MapSection>
      </Container>
    </SecondStepContainer>
  );
};

export default SecondStep;
